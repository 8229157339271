<template>
  <div
    class="pa-6 ma-6 mx-md-0 text-center"
    :style="{ 'background-color': slice.background_color, 'color': slice.text_color }"
  >
    <div v-if="slice.header && slice.header.length" class="font-title font-weight-bold">
      {{ slice.header[0].text }}
    </div>
    <div v-if="slice.sub_header && slice.sub_header.length" class="font-large font-weight-extra">
      {{ slice.sub_header[0].text }}
    </div>
    <base-button
      v-if="slice.button_label && slice.button_label.length"
      class="mt-6"
      :text="slice.button_label"
      :background-color="slice.button_color"
      @click="handleClick"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import prismicDOM from 'prismic-dom'
import linkResolver from '../../plugins/link-resolver'
import { getProductQuery } from '@/helpers/utilities'
import { PRODUCTS_TYPES } from '@/store/products'
export default {
  name: 'TextBanner',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.slice.button_link && this.slice.button_link.url) {
        if (this.slice.button_link.target) {
          window.open(this.slice.button_link.url, this.slice.button_link.target)
        } else {
          window.location = this.slice.button_link.url
        }
      } else if (this.slice.filter_type && this.slice.filter_value) {
        const query = getProductQuery(null, this.slice)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      } else {
        const linkUrl = prismicDOM.Link.url(this.slice.button_link, linkResolver)
        this.$router.push(linkUrl)
      }
    }
  }
}
</script>
