<template>
    <div
        v-if="banner"
        class="banner mb-16"
        :style="[banner.background_image && banner.background_image.url ? {backgroundImage: `url('${banner.background_image.url}')`} : {backgroundColor:'white'}]"
    >
        <div v-if="banner.banner_title && banner.banner_title.length" class="banner-title" :style="{ color: banner.title_text_color }">
        {{ banner.banner_title[0].text }}
        </div>
        <div v-if="banner.banner_paragraph && banner.banner_paragraph.length">
            <div v-for="paragraph in banner.banner_paragraph" :key="paragraph.index" :style="{ color: banner.paragraph_text_color}" class="font-large font-weight-extra text-center paragraph-spacing">
                {{ paragraph.text }}
            </div>
        </div>
        <base-button
        v-if="banner.button_label && banner.button_label.length"
        :text="banner.button_label[0].text"
        :background-color="banner.button_color"
        class="mt-4 mb-16"
        @click="handleClick"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import prismicDOM from 'prismic-dom'
import linkResolver from '../../plugins/link-resolver'
import { getProductQuery } from '@/helpers/utilities'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  name: 'GeneralPurposeBanner',
  props: {
    banner: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.banner.banner_button && this.banner.banner_button.url) {
        if (this.banner.banner_button.target) {
          window.open(this.banner.banner_button.url, this.banner.banner_button.target)
        } else {
          window.location = this.banner.banner_button.url
        }
      } else if (this.banner.filter_type && this.banner.filter_value) {
        const query = getProductQuery(null, this.banner)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      } else {
        const linkUrl = prismicDOM.Link.url(this.banner.banner_button, linkResolver)
        this.$router.push(linkUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.banner {
    height: 30em;
    left: 25%;
    margin: auto;
    background-repeat: no-repeat;
    background-position: 50% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em;

    .banner-title {
        position: relative;
        margin-top: 2em;
        margin-bottom: 0.75em;
        text-align: center;
        color: $white;
        font-size: 4em;
          &:after {
            content: '';
            width: 3em;
            border-top: 2px solid $charcoal-grey;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -20px;
            margin: auto;
          }
    }
}
.paragraph-spacing {
  margin: 8px auto;
}

@media only screen and (max-width: 768px) {
    .banner {
        font-size: 1.04vw;
        height: auto;
    }
    .paragraph-spacing {
        margin: 24px auto 8px;
    }
}
</style>
