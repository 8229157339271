<template>
    <div class="video-container">
        <div class="embedtool">
            <iframe :src="videoUrl" allowfullscreen frameBorder="0" style="border:none !important;" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'YoutubeEmbed',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    videoUrl () {
      return this.url
    }
  }
}
</script>

<style lang="scss" scoped>
    .video-container {
        height: 100%;
        width: 100%;
    }
    .embedtool {
            position: relative;
            height: 0;
            padding-top: 56%;
            overflow: hidden;
            max-width: 100%;
    }
    .embedtool iframe,
    .embedtool object,
    .embedtool embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
    .embedtool .fluid-vids {
        position: initial !important
    }
</style>
