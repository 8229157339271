<template>
  <section v-if="slice.items.length" class="group-container-four-col">
      <v-row class="product-group-header">
        <v-col xs="4" md="4" lg="4">
          <div v-if="slice.primary && slice.primary.product_group" class="product-group-title">
            {{ slice.primary.product_group[0].text }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2">
          <div class="product-group-title">
            2 oz
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2">
          <div class="product-group-title">
            8 oz
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2">
          <div class="product-group-title">
            12 oz
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2">
          <div class="product-group-title">
            16 oz
          </div>
        </v-col>
      </v-row>
      <v-row
        v-for="(item, i) of slice.items"
        :key="'content-section-slice-item_'+i"
        class="product-item-list"
      >
        <v-col xs="4" md="4" lg="4" class="no-vertical-padding">
          <div class="product-title">
          {{ item.product_name }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2" class="no-vertical-padding">
          <div class="product-price">
            {{ item.size2oz_price }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2" class="no-vertical-padding">
          <div class="product-price">
            {{ item.size8oz_price }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2" class="no-vertical-padding">
          <div class="product-price">
            {{ item.size12oz_price }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2" class="no-vertical-padding">
          <div class="product-price">
            {{ item.size16oz_price }}
          </div>
        </v-col>
      </v-row>
  </section>
</template>

<script>
export default {
  name: 'CafeItemFourPrices',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>
