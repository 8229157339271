<template>
  <section v-if="slice.items.length" class="group-container-four-col">
      <v-row class="product-group-header">
        <v-col xs="6" md="6" lg="6">
          <div v-if="slice.primary && slice.primary.product_group" class="product-group-title">
             {{ slice.primary.product_group }}
          </div>
        </v-col>
        <v-col cs="3" md="3" lg="3">
          <div class="product-group-title">
            16 oz
          </div>
        </v-col>
        <v-col cs="3" md="3" lg="3">
          <div class="product-group-title">
            24 oz
          </div>
        </v-col>
      </v-row>
      <v-row
        v-for="(item, i) of slice.items"
        :key="'content-section-slice-item_'+i"
        class="product-item-list"
      >
        <v-col xs="6" md="6" lg="6" class="no-vertical-padding">
          <div class="product-title">
          {{ item.product_name }}
          </div>
        </v-col>
        <v-col cs="3" md="3" lg="3" class="no-vertical-padding">
          <div class="product-price">
            {{ item.size1_price }}
          </div>
        </v-col>
        <v-col cs="3" md="3" lg="3" class="no-vertical-padding">
          <div class="product-price">
            {{ item.size2_price }}
          </div>
        </v-col>
      </v-row>
  </section>
</template>

<script>
export default {
  name: 'CafeItemTwoPrices',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>
