<template>
    <div class="d-flex flex-column flex-sm-row align-center my-10">
    <img
            v-a11y-clickable
            :src="slice.items[0].image.url"
            :alt="slice.items[0].image.alt || 'Featured Image'"
            class="image clickable"
            @click="handleClick"
        >
    </div>
</template>

<script>

export default {
  name: 'ImageClickable',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick () {
      if (this.slice.items) {
        if (this.slice.items[0].url.target) {
          window.open(this.slice.items[0].url.url, this.slice.items[0].url.target)
        } else {
          window.location = this.slice.items[0].url.url
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
  }

  @media only screen and (max-width: 960px) {
  }
</style>
