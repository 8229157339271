<template>
  <div class="ma-6 mx-md-0 text-center">
    <prismic-rich-text :field="slice.primary.header" class="bpg-title font-weight-bold mb-6" />
    <div class="d-flex flex-wrap justify-center">
      <a
        v-for="(filter, i) of ($vuetify.breakpoint.xs ? slice.items.slice(0, 6) : slice.items)"
        :key="'filter_' + i"
        v-a11y-clickable.link
        class="filter-button clickable font-weight-bold d-flex align-center justify-center"
        @click="navigate(filter)"
      >
        {{ filter.filter_name }}
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { PRODUCTS_TYPES } from '@/store/products'
import { getProductQuery } from '@/helpers/utilities'

export default {
  name: 'QuickFilters',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    navigate (filter) {
      const query = getProductQuery(null, filter)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        return
      }
      this.resetProductList()
      this.$router.push({ name: 'products', query })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './scss/variables';

  .filter-button {
    border: 1px solid $dark-blue;
    border-radius: 34px;
    color: $dark-blue;
    background-color: white;
    font-size: 15px;
    padding: 10px;
    width: 145px;
    text-decoration: none;
    margin: 8px;

    &:hover {
      background-color: $dark-blue;
      color: white;
    }
    &:active {
      background-color: #214a6f;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .filter-button {
      font-size: 12px;
      padding: 6px;
      margin: 4px;
      width: 120px;
    }
  }
</style>
