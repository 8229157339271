<template>
    <div v-if="slice" class="slice-container">
      <div style="position: relative; height: 100%">
        <div
          v-if="slice.primary"
          class="banner mb-16"
          :class="slice.primary.banner_background_color"
          :style="slice.primary.banner_float === 'Right' ? {left: `50%`} : {left: `0`}"
        >
          <div v-if="slice.primary.heading" class="banner-title" :style="{ color: slice.primary.heading_text_color }">
            {{ slice.primary.heading[0].text }}
          </div>
          <div v-if="slice.primary.paragraph" :style="{ color: slice.primary.paragraph_text_color}" class="font-large font-weight-bold text-center paragraph-spacing">
            {{ slice.primary.paragraph[0].text }}
          </div>
          <base-button
          v-if="slice.primary.button_label1 && slice.primary.button_label1.length"
          :text="slice.primary.button_label1[0].text"
          :background-color="slice.primary.button_color1"
          class="mt-4 mb-0"
          @click="handleClick"
          />
        </div>
        <div>
          <div v-if="slice.primary.image">
            <div :max-width="slice.primary.image.dimensions.width" class="image" :style="slice.primary.banner_float === 'Right' ? {marginLeft: `0`} : {marginLeft: `50%`}">
              <img v-if="slice.primary.image && slice.primary.image.url" :src="slice.primary.image.url" width="100%">
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import prismicDOM from 'prismic-dom'
import linkResolver from '../../plugins/link-resolver'
import { getProductQuery } from '@/helpers/utilities'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  name: 'ImageFloatingBanner',
  props: {
    slice: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.slice.primary.button_link && this.slice.primary.button_link.url) {
        if (this.slice.primary.button_link.target) {
          window.open(this.slice.primary.button_link.url, this.slice.primary.button_link.target)
        } else {
          window.location = this.slice.primary.button_link.url
        }
      } else if (this.slice.primary.filter_type && this.slice.primary.filter_value) {
        const query = getProductQuery(null, this.slice.primary)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      } else {
        const linkUrl = prismicDOM.Link.url(this.slice.primary.button_link, linkResolver)
        this.$router.push(linkUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.banner {
    position: absolute;
    height: 30em;
    width: 75%;
    top: 20%;
    margin: auto;
    background-repeat: no-repeat;
    background-position: 50% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em 5em 10em 5em;

    .banner-title {
        position: relative;
        margin-top: 2em;
        text-align: center;
        color: $white;
        font-size: 4em;
    }
}
.paragraph-spacing {
  margin: 8px auto;
}
.image {
  width: 75%;
}
.slice-container {
  margin-top: 64px;
  margin-bottom: 64px;
  width: 75%;
}

@media only screen and (max-width: 768px) {
    .banner {
        font-size: 1.04vw;
        height: auto;
        top: 66%;
        left: 7.5% !important;
        width: 85% !important;
    }
    .paragraph-spacing {
        margin: 24px auto 8px;
    }
    .image {
      margin-left: 0% !important;
      width: 100% !important;
    }
    .slice-container {
      width: 100%;
      margin-top: 5em;
      margin-bottom: 20em;
    }
}
</style>
