<template>
  <div class="scale-with-screen">
    <div class="book-cover-reel">
      <div v-if="gridTitle && gridTitle.length > 0" class="bpg-title" :class="titleClasses">
        <span>{{ gridTitle }}</span>
      </div>
      <base-loader v-if="loading || loadingInternal" />
      <div v-if="!loading" class="book-cover-container">
        <book-cover-thumbnail
              v-for="(item, i) of items"
              :key="'book-cover-thumbnail_'+item.id"
              :item="item"
              :class="{
                'last-book-cover-thumbnail': (i === items.length -1)
              }"
              :cart-item="cartItem"
              class="flex-item"
            />
      </div>
    </div>
  </div>
</template>

<script>
import BookCoverThumbnail from '@/components/content-sections/book-cover-thumbnail'

export default {
  name: 'BookCoverCSVGrid',
  components: { BookCoverThumbnail },
  props: {
    gridTitle: {
      type: String,
      default: 'New Releases'
    },
    gridItems: {
      type: Array,
      default: () => ([])
    },
    randomList: {
      type: Boolean,
      default: false
    },
    titleClasses: {
      type: String,
      default: null
    },
    cartItem: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: [],
      loadingInternal: false
    }
  },
  /* watch: {
    'gridItems' () {
      this.setItems()
    }
  }, */
  created () {
    this.setItems()
  },
  methods: {
    setItems () {
      if (this.gridItems && this.gridItems.length && this.gridItems[0].csv_text && !this.items.length) {
        const isbnsList = this.gridItems[0].csv_text.split(',')
        this.loadingInternal = true
        const isbns = {
          ISBNList: isbnsList
        }
        // const query = isbns.map((x, i) => (i === 0 ? '?' : '&') + 'isbns=' + x)
        //   .join('')
        // const res = this.$axios.get(`/store/Products/get-product-list${query}`, {}, true)
        // by-extended-isbns
        return this.$axios.post('store/products/by-extended-isbns', isbns)
          .then((res) => {
            if (this.randomList && this.randomList === true) {
              res.data = res.data.sort((a, b) => Math.random() > 0.5 ? -1 : 1)
            }
            this.items = res.data
          })
          .finally(() => {
            this.loadingInternal = false
          })
      } else {
        this.items = this.gridItems
      }
      if (this.randomList && this.randomList === true) {
        this.items = this.items.sort((a, b) => Math.random() > 0.5 ? -1 : 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';
  .book-cover-reel {
    position:relative;
    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 20em;
      z-index: 0;
    }
  }
  .book-cover-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    z-index: 1;
    margin-top:3em;
    font-size: .7575vw;
    .book-cover-thumbnail {
      margin-top:3em;
    }
  }
  @media only screen and (max-width: 500px) {
    .bpg-title {
      font-size:220%;
    }
    .book-cover-container {
      font-size: 220%;
    }
  }
</style>
