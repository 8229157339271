<template>
    <div v-if="slice">
      <!-- <div>{{ slice }}</div> -->
      <div
        v-if="slice.primary"
        class="banner"
        :style="[slice.primary.banner_background && slice.primary.banner_background.url ? {backgroundImage: `url('${slice.primary.banner_background.url}')`} : {backgroundColor:'white'}]"
      >
        <div v-if="slice.primary.banner_title1 && slice.primary.banner_title1.length" class="banner-title" :style="{ color: slice.primary.title_text_color }">
        {{ slice.primary.banner_title1[0].text }}
        </div>
        <div v-if="slice.primary.banner_paragraph && slice.primary.banner_paragraph.length">
          <div v-for="paragraph in slice.primary.banner_paragraph" :key="paragraph.index" :style="{ color: slice.primary.paragraph_text_color}" class="font-large font-weight-extra text-center paragraph-spacing">
            {{ paragraph.text }}
          </div>
        </div>
        <base-button
        v-if="slice.primary.button_label1 && slice.primary.button_label1.length"
        :text="slice.primary.button_label1[0].text"
        :background-color="slice.primary.button_color1"
        class="mt-4 mb-0"
        @click="handleClick"
        />
      </div>
      <div class="images-box px-16 pt-1 pb-16" :class="slice.primary.banner_background_color">
        <div v-if="slice.primary.images_heading && slice.primary.images_heading.length" class="images-title font-x-large">
          {{ slice.primary.images_heading[0].text }}
        </div>
        <div v-if="slice.items.length" class="img-caption-box">
          <div v-for="(item, i) in slice.items" :key="'image_with_caption'+i" class="img-caption">
            <div class="image">
              <img v-if="item.image && item.image.url" :src="item.image.url" width="100%">
            </div>
            <div v-if="item.caption && item.caption.length" class="font-x-large font-weight-bold narrow-text">
              {{ item.caption[0].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import prismicDOM from 'prismic-dom'
import linkResolver from '../../plugins/link-resolver'
import { getProductQuery } from '@/helpers/utilities'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  name: 'BannerPlusImages',
  props: {
    slice: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.slice.primary.banner_button && this.slice.primary.banner_button.url) {
        if (this.slice.primary.banner_button.target) {
          window.open(this.slice.primary.banner_button.url, this.slice.primary.banner_button.target)
        } else {
          window.location = this.slice.primary.banner_button.url
        }
      } else if (this.slice.primary.filter_type && this.slice.primary.filter_value) {
        const query = getProductQuery(null, this.slice.primary)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      } else {
        const linkUrl = prismicDOM.Link.url(this.slice.primary.banner_button, linkResolver)
        this.$router.push(linkUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.banner {
    height: 30em;
    left: 25%;
    width: 100%;
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em 5em 30em 5em;

    .banner-title {
        position: relative;
        margin-top: 6em;
        margin-bottom: 0.75em;
        text-align: center;
        color: $white;
        font-size: 4em;
    }
}
.paragraph-spacing {
  margin: 8px auto;
}

.img-caption-box{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  padding: 4px 64px 64px 64px;
}
.img-caption {
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  margin-right: 60px;
}
.narrow-text {
  width: 10em;
  color: $white;
  text-align: center;
}
.images-box{
  margin: auto;
}

.images-title {
  position: relative;
  margin-top: 1.6em;
  margin-bottom: 1.2em;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  &:after {
    content: '';
    width: 3.8em;
    border-top: 2px solid $white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
    .banner {
        font-size: 1.04vw;
        height: auto;
        padding: 5em 5em 15em 5em;
    }
    .paragraph-spacing {
        margin: 24px auto 8px;
    }
    .img-caption-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: start;
      justify-content: center;
      align-items: stretch;
      padding: 4px 14px 14px 14px;
    }
    .img-caption {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-content: space-evenly;
      margin: 30px auto 0px auto;
    }
    .narrow-text {
      width: 10em;
      color: $white;
      text-align: left;
    }
    .images-box {
      padding: 2px !important;
    }
    .image {
      max-width: 50%;
    }
}
</style>
