<template>
  <div>
    <div v-if="slice.text" class="my-10">
      <prismic-rich-text
        :field="slice.text"
        class="font-title font-weight-bold mx-12"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Quote',
  props: {
    slice: {
      type: Object,
      required: true
    }
  }
}
</script>
