<template>
  <div v-if="items" class="scale-with-screen">
    <div class="recent-blogs">
      <div v-if="title" class="bpg-title">
        <span>{{ title }}</span>
      </div>
      <div class="recent-blog-scroller">
        <div class="recent-blog-list">
          <blog-item
            v-for="(item, i) of items"
            :key="'blog-list-item'+i"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlogItem from '@/components/slices/blog-item'

export default {
  name: 'BlogItems',
  components: { BlogItem },
  props: {
    title: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .recent-blogs {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.4em;
  }

  .recent-blog-scroller {
    margin-top: 2.8em;
    position: relative;
    width: 100%;
  }

  .recent-blog-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @media only screen and (max-width: 750px) {
    .recent-blog-scroller {
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }
    .recent-blogs {
      font-size: 300%;
    }
    .recent-blog-list {
      font-size: 95%;
    }
  }
</style>
