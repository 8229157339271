<template>
  <div>
    <v-container v-if="slice" class="my-5">
        <div
            v-if="slice.primary && slice.primary.optional_image && slice.primary.optional_image.url"
            class="banner-image-container clickable"
            :style="{ backgroundImage: `url('${slice.primary.optional_image.url}')`, color: slice.primary.text_color}"
            @click="handleClick"
        >
            <div v-if="slice.primary.title.length > 0" class="faq-title">
            {{ slice.primary.title[0].text }}
            </div>
            <div v-if="slice.primary.description.length > 0" class="faq-description">
                {{ slice.primary.description[0].text }}
            </div>
        </div>
        <div v-else>
            <div v-if="slice.primary.title.length > 0" class="faq-title">
            {{ slice.primary.title[0].text }}
            </div>
            <div v-if="slice.primary.description.length > 0" class="faq-description">
                {{ slice.primary.description[0].text }}
            </div>
        </div>
        <div v-if="slice.items" class="faq-list">
          <div
            v-for="(item, i) of slice.items"
            :key="'faq-list-item' + i"
            :item="item"
            class="faq-group"
          >
            <div class="faq-item">
            <div class="faq-question font-large mt-1">
                <a @click="item.showAnswer = !item.showAnswer">
                <v-icon v-if="!item.showAnswer" class="primary--text font-large mr-2">
                        mdi-plus
                    </v-icon>
                <v-icon v-if="item.showAnswer" class="primary--text font-large mr-2">
                        mdi-minus
                    </v-icon>
                <span class="faq-question">{{ item.title[0].text }}</span></a>
            </div>
            <div v-if="item.showAnswer" class="font-large px-8 mt-2 highlighted">
                <p>{{ item.text[0].text }}</p>
            </div>
            </div>
        </div>
        </div>
  </v-container>
  </div>
</template>

<script>

export default {
  name: 'FaqSection',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick () {
      if (this.slice.primary.apply_now_url && this.slice.primary.apply_now_url.url) {
        window.location = this.slice.primary.apply_now_url.url
      }
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './scss/variables';
    .banner-image-container {
        height: 30em;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em;
    color: $charcoal-grey;
    .apply-button {
        margin-top:3em;
    }
    }
    .faq-title {
        font-size:3em;
        text-align:center;
    }
    .faq-list {
        margin-top:1em;
        border-top:1px solid #dcdcdc;
        padding-top:.6em;
    }
    .faq-description {
        font-size:2em;
        text-align:center;
        font-weight:100;
    }
    .highlighted {
      background-color: #f1f4ed;
      padding:10px;
    }
    .faq-question {
      a:hover {
        text-decoration:none !important;
      }
    }
    .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
  }
</style>
