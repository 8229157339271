<template>
  <nuxt-link
    :to="'/products/'+((cartItem) ? item.product.id : item.id)"
    exact
    tag="a"
    class="book-cover-thumbnail clickable"

    :aria-label="item.title + ' by ' + item.author"
    :class="{'no-hover': noHover}"
  >
    <div class="image-container">
      <div class="image">
        <base-image
          v-if="cartItem"
          :source="item.product.featuredImageUrl | fullImagePath('S')"
          :alt="item.product.title"
          :style="{ width: imgWidth }"
        />
        <base-image
          v-else
          :source="item.featuredImageUrl | fullImagePath('S')"
          :alt="item.title"
          :style="{ width: imgWidth }"
        />
      </div>
    </div>
    <div v-if="!coverOnly" class="content">
      <div class="book-title">
        <span>{{ item.title }}</span>
      </div>
      <div v-if="item.author" class="by">
        <span>by {{ item.author }}</span>
      </div>
      <div class="price font-weight-bold">
        <span>{{ item.price | formatCurrency }}</span>
        </div>
        <div v-if="(item.msrp && item.msrp !== item.price)" class="old-price font-weight-bold ml-1">
          <span>{{ item.msrp | formatCurrency }}</span>
        </div>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  name: 'BookCoverThumbnail',
  props: {
    item: {
      type: Object,
      required: true
    },
    imgWidth: {
      type: String,
      default: '14.5em'
    },
    coverOnly: {
      type: Boolean,
      default: false
    },
    noHover: {
      type: Boolean,
      default: false
    },
    cartItem: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

  .book-cover-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 .75em;
    text-decoration:none;
    &:not(.no-hover) {
      .image {
        transition: box-shadow .3s;
      }
      &:hover .image {
        box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.2);
      }
    }
    .image-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 22.4em;
    }
    .image {
      img {
        width: 100%;
      }
    }
    .content {
      text-align: center;
      width: 16.4em;
    }
    .book-title {
      color: $dark-grey;
      margin-top: 3.5em;
      font-weight: $font-weight-bold;
      span {
        font-size: 1.3em;
      }
    }
    .by {
      color: $grey;
      // margin-top: .7em;
      span {
        font-size: 1.1em;
        // display:none;
      }
    }
    .price {
      margin-top: 0.7em;
      display: inline-block;
      span {
        color: $soft-green;
        font-size: 1.3em;
      }
    }
    .old-price {
      display: inline-block;
      span {
        color: $grey;
        text-decoration: line-through;
        font-size: 1.3em;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .book-cover-thumbnail {
      font-size: 10px;
    }
  }

  @media screen and (min-width: 2150px) {
    .book-cover-thumbnail {
      font-size: 10px;
    }
  }
</style>
