<template>
  <div>
    <section v-for="(slice, index) in slices" :key="'slice-' + index">
      <template>
        <content-section v-if="slice.slice_type === 'content_section'" :slice="slice" />
        <spotlight-section v-if="slice.slice_type === 'spotlight'" :slice="slice" />
        <page-text v-if="slice.slice_type === 'page_text'" :slice="slice" />
        <image-banner v-if="slice.slice_type === 'banner_with_caption'" :banner="slice.primary" />
        <image-clickable v-if="slice.slice_type === 'image_clickable'" :slice="slice" />
        <hero-image v-if="slice.slice_type === 'hero_image'" :slice="slice" />
        <youtube-video v-if="slice.slice_type === 'youtube_video'" :slice="slice" />
        <book-cover-reel
          v-if="slice.slice_type === 'featured_items'"
          :reel-title="slice.primary && slice.primary.title && slice.primary.title.length && slice.primary.title[0].text"
          :reel-items="slice.items"
          :random-list="slice.primary && slice.primary.randomize_list"
        />
        <book-cover-csv-grid
          v-if="slice.slice_type === 'items_grid'"
          :grid-title="slice.primary && slice.primary.grid_title && slice.primary.grid_title.length && slice.primary.grid_title[0].text"
          :grid-items="slice.items"
          :random-list="slice.primary && slice.primary.randomize_list"
        />
        <book-cover-reel
          v-if="slice.slice_type === 'book_reel'"
          :reel-title="slice.primary && slice.primary.title && slice.primary.title.length && slice.primary.title[0].text"
          :reel-items="slice.items"
          :random-list="slice.primary && slice.primary.randomize_list"
          :nested="true"
        />
        <highlights v-if="slice.slice_type === 'highlights'" :items="slice.items" />
        <blog-items
          v-if="slice.slice_type === 'blog_posts'"
          :title="slice.primary && slice.primary.title"
          :items="slice.items"
        />
        <header-with-tiles v-if="slice.slice_type === 'header_with_tiles'" :slice="slice" />
        <quick-filters v-if="slice.slice_type === 'quick_filters'" :slice="slice" />
        <event-card v-if="slice.slice_type === 'event'" :slice="slice.primary" />
        <text-banner v-if="slice.slice_type === 'text_banner'" :slice="slice.primary" />
        <quote v-if="slice.slice_type === 'quote'" :slice="slice.primary" />
        <image-with-text v-if="slice.slice_type === 'image_with_text'" :slice="slice.primary" />
        <cafe-item-four-prices v-if="slice.slice_type === 'drink_item'" :slice="slice" />
        <cafe-item-two-prices v-if="slice.slice_type === 'dual_price_item'" :slice="slice" />
        <cafe-item-single-price v-if="slice.slice_type === 'drink_item_single_price'" :slice="slice" />
        <faq-question v-if="slice.slice_type === 'faq_items'" :slice="slice" />
        <faq-section v-if="slice.slice_type === 'faq_section'" :slice="slice" />
        <hero-banner v-if="slice.slice_type === 'hero_banner'" :slice="slice.primary" />
        <general-purpose-banner v-if="slice.slice_type === 'general_purpose_banner'" :banner="slice.primary" />
        <text-with-header v-if="slice.slice_type === 'text_with_header'" :slice="slice" />
        <three-image-caption v-if="slice.slice_type === 'three_image_caption'" :slice="slice" />
        <banner-plus-images v-if="slice.slice_type === 'banner_plus_images'" :slice="slice" />
        <image-floating-banner v-if="slice.slice_type === 'image_floating_banner'" :slice="slice" />
</template>
</section>
  </div>
</template>

<script>
import ContentSection from './content-section'
import SpotlightSection from './spotlight-section'
import PageText from './page-text'
import Highlights from './highlights'
import BlogItems from '@/components/slices/blog-items'
import ImageBanner from '@/components/slices/image-banner'
import BookCoverReel from '@/components/content-sections/book-cover-reel'
import BookCoverCsvGrid from '@/components/content-sections/book-cover-csv-grid'
import HeaderWithTiles from '@/components/slices/header-with-tiles'
import QuickFilters from '@/components/slices/quick-filters'
import EventCard from '@/components/slices/event-card'
import ImageClickable from '@/components/slices/image-clickable'
import HeroImage from '@/components/slices/hero-image'
import TextBanner from '@/components/slices/text-banner'
import Quote from '@/components/slices/quote'
import ImageWithText from '@/components/slices/image-with-text'
import CafeItemFourPrices from '@/components/slices/kiosk/cafe-item-four-prices'
import CafeItemTwoPrices from '@/components/slices/kiosk/cafe-item-two-prices'
import CafeItemSinglePrice from '@/components/slices/kiosk/cafe-item-single-price'
import FaqQuestion from '@/components/slices/faq/faq-question'
import FaqSection from '@/components/slices/faq/faq-section'
import YoutubeVideo from '@/components/slices/youtube-video'
import HeroBanner from '@/components/slices/hero-banner'
import GeneralPurposeBanner from '@/components/slices/general-purpose-banner'
import TextWithHeader from '@/components/slices/text-with-header'
import ThreeImageCaption from '@/components/slices/three-image-caption'
import BannerPlusImages from '@/components/slices/banner-plus-images'
import ImageFloatingBanner from '@/components/slices/image-floating-banner'

export default {
  name: 'SlicesBlock',
  components: {
    HeaderWithTiles,
    QuickFilters,
    BlogItems,
    ImageBanner,
    Highlights,
    BookCoverReel,
    BookCoverCsvGrid,
    ContentSection,
    SpotlightSection,
    PageText,
    EventCard,
    ImageClickable,
    HeroImage,
    TextBanner,
    Quote,
    ImageWithText,
    CafeItemFourPrices,
    CafeItemTwoPrices,
    CafeItemSinglePrice,
    FaqQuestion,
    FaqSection,
    YoutubeVideo,
    HeroBanner,
    GeneralPurposeBanner,
    TextWithHeader,
    ThreeImageCaption,
    BannerPlusImages,
    ImageFloatingBanner
  },
  props: {
    slices: {
      type: Array,
      required: true
    }
  }
}
</script>
