<template>
  <div
    class="d-flex flex-column flex-sm-row align-center pa-6 my-10"
    :class="{'pa-sm-0' : !slice.padding}"
    :style="{
      'background-color': slice.background_color,
      'color': slice.text_color
    }"
  >
    <!-- watch for this url format - it is the most likely to work -->
    <!-- https://www.youtube.com/embed/8csiG7xEQHM?autoplay=0&rel=0&loop=1 -->
    <youtube-embed v-if="slice && slice.primary" class="video-player" :url="sliceURL" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import YoutubeEmbed from '@/components/multimedia/youtube-embed'

export default {
  name: 'YoutubeVideo',
  components: {
    YoutubeEmbed
  },
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  computed: {
    sliceURL () {
      if (this.slice && this.slice.primary && this.slice.primary.video_id) {
        return 'https://www.youtube.com/embed/' + this.slice.primary.video_id + '?autoplay=0&rel=0&loop=1'
      }
      return 'https://www.youtube.com/embed/8csiG7xEQHM?autoplay=0&rel=0&loop=1'
    }
  },
  methods: {
    ...mapActions({
    })
  }
}
</script>
