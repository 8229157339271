<template>
  <div>
    <div v-if="slice.primary" class="my-16">
      <div v-if="slice.primary.heading && slice.primary.heading.length" class="bpg-title font-x-large my-16 font-weight-bold">
        {{ slice.primary.heading[0].text }}
      </div>
      <div v-if="slice">
        <prismic-rich-text
            v-for="(item, i) in slice.items"
            :key="'page-text-slice_'+i"
            :field="item.paragraphs"
            class="page-text font-large"
          />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextWithHeader',
  props: {
    slice: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.page-text {
  width: 90%;
  margin: auto;
}
</style>
