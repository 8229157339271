<template>
  <section>
    <v-expansion-panels v-if="$breakpoint.is.smAndDown" v-model="expanded" class="px-3 py-2">
      <v-expansion-panel class="mobile-panel">
        <v-expansion-panel-header :expand-icon="expanded === 0 ? 'mdi-minus' : 'mdi-plus'">
          <prismic-rich-text
            :field="slice.primary.spotlight_title"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <img
            :src="slice.primary.spotlight_image.url"
            :alt="slice.primary.spotlight_image.alt || 'Spotlight Image'"
            class="img-contain"
          >
          <div v-if="slice.items.length">
            <div
              v-for="(item, i) of slice.items"
              :key="'spotlight-slice-item_'+i"
              class="mobile-section my-4"
            >
              <div class="text-center">
                <img
                  :src="item.supporting_image.url"
                  :alt="item.supporting_image.alt || 'Supporting Section Image'"
                  class="img-contain mb-2"
                >
              </div>
              <div class="font-weight-light">
                <prismic-rich-text
                  :field="item.supporting_content"
                />
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-if="$breakpoint.is.mdAndUp">
      <v-row v-if="slice.primary">
        <v-col xs="12" md="12" lg="12">
          <div class="bpg-block-headboard--light-grey" />
          <div class="bpg-title">
            <prismic-rich-text
              :field="slice.primary.spotlight_title"
            />
          </div>
          <img
            :src="slice.primary.spotlight_image.url"
            :alt="slice.primary.spotlight_image.alt || 'Spotlight Image'"
            class="img-contain"
          >
        </v-col>
      </v-row>
      <div v-if="slice.items.length">
        <v-row
          v-for="(item, i) of slice.items"
          :key="'spotlight-slice-item_'+i"
          class="align-center flex-wrap my-4"
        >
          <v-col xs="12" sm="12" md="3" lg="3">
            <img
              :src="item.supporting_image.url"
              :alt="item.supporting_image.alt || 'Supporting Section Image'"
              class="img-contain"
            >
          </v-col>
          <v-col xs="12" md="9" lg="9" class="font-default-size font-weight-light">
            <prismic-rich-text
              :field="item.supporting_content"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SpotlightSection',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .bpg-title {
    font-size: 10px;
    margin: 2.6em 0;

    * {
      font-size: 2em;
      font-weight: $font-weight-default;
    }
  }

  .mobile-section img {
    width: 40% !important;
  }
</style>
