<template>
  <div
    :style="{ 'background-color': slice.primary.background_color, 'color': slice.primary.text_color }"
    class="pb-6 pa-md-6 text-center tile-container"
  >
    <div class="pa-6 pa-md-0 pt-md-2">
      <div v-if="slice.primary.header && slice.primary.header.length" class="font-title font-weight-bold">
        {{ slice.primary.header[0].text }}
      </div>
      <div v-if="slice.primary.sub_header && slice.primary.sub_header.length" class="font-x-large font-weight-bold">
        {{ slice.primary.sub_header[0].text }}
      </div>
    </div>
    <div class="d-md-flex justify-md-space-around tile-row">
      <a
        v-for="(tile, j) of slice.items"
        :key="'tile_' + j"
        :href="tile.url ? tile.url.url : null"
        :target="tile.url ? tile.url.target : null"
        class="fx-1 mx-3 mx-md-5 tile"
        @click="handleClick(tile)"
      >
        <div class="tile-text-clear d-flex flex-column justify-center align-center text-center clickable" :class="{ 'tile-text': !tile.tile_clear }">
          <span>{{ tile.tile_text }}</span>
        </div>
        <v-img
          :src="tile.tile_image.url"
          :aspect-ratio="0.777"
        />
      </a>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex'
import { PRODUCTS_TYPES } from '@/store/products'
import { getProductQuery } from '@/helpers/utilities'

export default {
  name: 'HeaderWithTiles',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick (tile) {
      if (tile && tile.url && tile.url.url) {
        return
      }
      const query = getProductQuery(null, tile)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        return
      }
      this.resetProductList()
      this.$router.push({ name: 'products', query })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './scss/variables';

  .tile-container {
    margin-bottom: 100px;

    .tile-row {
      margin-top: 32px;
      margin-bottom: -80px;
    }

    .tile {
      position: relative;
    }

    .tile-text {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;

      margin: auto;
      font-size: 21px;
      font-weight: $font-weight-bold;
      color: white;
      background-color: rgba($grey, 0.6);
      transition: box-shadow .3s;

      span {
        width: 100%;
        padding: 5px;
      }

      &:hover {
        box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.2);
      }
    }
    .tile-text-clear {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;

      margin: auto;
      font-size: 21px;
      font-weight: $font-weight-bold;
      color: white;
      transition: box-shadow .3s;

      span {
        width: 100%;
        padding: 5px;
      }

      &:hover {
        box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .tile-container {
      margin-bottom: 80px;

      .tile-row {
        overflow-x: auto;
        white-space: nowrap;
        margin-top: 0;
        margin-bottom: -60px;

        &::-webkit-scrollbar {
          height: 0;
        }
      }

      .tile {
        display: inline-block;
        float: none;
        white-space: normal;
        width: 120px;
        height: 155px;
      }

      .tile-text {
        font-size: 14px;
        font-weight: $font-weight-extra;
        width: 120px;
        height: 155px;

        span {
          width: 110px;
        }
      }
    }
  }

</style>
