<template>
    <div class="d-flex flex-column flex-sm-row align-center">
   <img
            :src="slice.primary.image.url"
            :alt="slice.primary.image.alt || 'Featured Image'"
            class="image"
        >
    </div>
</template>

<script>

export default {
  name: 'HeroImage',
  props: {
    slice: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
  }

  @media only screen and (max-width: 960px) {
  }
</style>
