<template>
  <div
    v-if="banner && banner.banner_image && banner.banner_image.url"
    class="banner"
    :class="{
      'hero': hero,
      'ma-6 mx-md-0': !hero,
      'align-start': banner.alignment === 'Left',
      'align-end': banner.alignment === 'Right',
      'align-center': banner.alignment === 'Center'
    }"
    :style="{ backgroundImage: `url('${banner.banner_image.url}')`, backgroundSize: `${banner.background_size}`}"
  >
    <h1 v-if="banner.banner_title && banner.banner_title.length" class="banner-title" :style="{ color: banner.text_color }">
      {{ banner.banner_title[0].text }}
    </h1>
    <base-button
      v-if="banner.button_label && banner.button_label.length"
      :text="banner.button_label"
      :background-color="banner.button_color"
      :uppercase="!hero"
      @click="handleClick"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import prismicDOM from 'prismic-dom'
import linkResolver from '../../plugins/link-resolver'
import { getProductQuery } from '@/helpers/utilities'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  name: 'ImageBanner',
  props: {
    banner: {
      type: Object,
      default: null
    },
    hero: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.banner.button_link && this.banner.button_link.url) {
        if (this.banner.button_link.target) {
          window.open(this.banner.button_link.url, this.banner.button_link.target)
        } else {
          window.location = this.banner.button_link.url
        }
      } else if (this.banner.filter_type && this.banner.filter_value) {
        const query = getProductQuery(null, this.banner)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      } else {
        const linkUrl = prismicDOM.Link.url(this.banner.button_link, linkResolver)
        this.$router.push(linkUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

  .banner {
    height: 30em;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em;

    .banner-title {
      margin-bottom: 0.75em;
      margin-top: 0.9em;
      text-align: center;
      color: $white;
      font-size: 4em;
    }

    &.hero {
      height: 45em;

      .banner-title {
        font-size: 6em;
      }
    }
  }

  @media only screen and (max-width: 960px) {
    .banner {
      font-size: 1.04vw;
    }
  }
</style>
