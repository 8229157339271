<template>
  <div
    class="d-flex flex-column flex-sm-row align-center pa-6 my-10"
    :class="{'pa-sm-0' : !slice.padding}"
    :style="{
      'background-color': slice.background_color,
      'color': slice.text_color
    }"
  >
    <v-img
      v-if="slice.image && slice.image.url"
      :src="slice.image.url"
      :alt="slice.image.alt"
      :class="{'order-0': slice.image_alignment === 'left', 'order-1': slice.image_alignment === 'right'}"
      :max-width="$breakpoint.is.smAndDown || slice.image_width === null ? 190 : slice.image_width"
    />
    <div v-if="slice.text" class="mx-sm-8 my-2">
      <div class="font-title font-weight-bold">
        {{ slice.text }}
      </div>
      <div v-if="slice.subtext" class="font-x-large font-italic font-weight-bold mt-2">
        {{ slice.subtext }}
      </div>
      <base-button
        v-if="slice.button_label && slice.button_label.length"
        class="mt-6"
        :text="slice.button_label"
        :background-color="slice.button_color"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import prismicDOM from 'prismic-dom'
import linkResolver from '../../plugins/link-resolver'
import { getProductQuery } from '@/helpers/utilities'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  name: 'ImageWithText',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.slice.button_link && this.slice.button_link.url) {
        if (this.slice.button_link.target) {
          window.open(this.slice.button_link.url, this.slice.button_link.target)
        } else {
          window.location = this.slice.button_link.url
        }
      } else if (this.slice.filter_type && this.slice.filter_value) {
        const query = getProductQuery(null, this.slice)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      } else {
        const linkUrl = prismicDOM.Link.url(this.slice.button_link, linkResolver)
        this.$router.push(linkUrl)
      }
    }
  }
}
</script>
