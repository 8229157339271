<template>
  <div
    v-a11y-clickable
    class="feature-list-item clickable"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="handleClick"
  >
    <div class="image">
      <img
        :src="item.highlight_image.url"
        :alt="item.highlight_image.alt || 'Featured Image'"
      >
    </div>
    <div class="content">
      <div class="label">
        <span>{{ item.highlight_type }}</span>
      </div>
      <div class="featured-item-title">
        <span>{{ item.highlight_name }}</span>
      </div>
      <div class="subtitle">
        <span>{{ item.highlight_description }}</span>
      </div>
      <base-button
        :text="item.highlight_action"
        :hover="hover"
        color="primary"
        class="action"
        :aria-label="item.highlight_type + ' ' + item.highlight_name + ' ' + item.highlight_action"
        :class="[{'xs': $vuetify.breakpoint.xs}, {'sm': $vuetify.breakpoint.sm} ]"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { PRODUCTS_TYPES } from '@/store/products'
import { getProductQuery } from '@/helpers/utilities'

export default {
  name: 'Highlight',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hover: false
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleClick () {
      if (this.item.action_link && this.item.action_link.url) {
        if (this.item.action_link.target) {
          window.open(this.item.action_link.url, this.item.action_link.target)
        } else {
          window.location = this.item.action_link.url
        }
      } else if (this.item.filter_type && this.item.filter_value) {
        const query = getProductQuery(null, this.item)
        if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
          return
        }
        this.resetProductList()
        this.$router.push({ name: 'products', query })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

  .feature-list-item {
    font-size: 1em;
    width: 38.3em;
    margin-bottom: 6.4em;
    transition: box-shadow .3s;

    @media only screen and (min-width: 750px) {
      &:not(:last-child) {
        margin-right: 2.5em;
      }
    }

    .image {
      img {
        width: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #e4e4e4;
      border-top: none;
      background-color: white;
      .label {
        text-transform: uppercase;
        background-color: $white;
        margin-top: -2.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21.8em;
        height: 5.9em;
        span {
          font-size: 1.5em;
        }
      }
      .featured-item-title {
        margin-top: 1.3em;
        padding: 0 2.5em;
        text-align: center;
        overflow: hidden;
        span {
          font-size: 2.1em;
        }
      }
      .subtitle {
        margin-top: .8em;
        span {
          font-size: 1.7em;
        }
      }
      .action {
        margin-top: 37px;
        margin-bottom: -24px;

        &.xs {
          margin-top: 24px;
          margin-bottom: -14px;
        }
        &.sm {
          margin-top: 16px;
          margin-bottom: -18px;
        }
      }
    }
  }
</style>
