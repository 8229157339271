<template>
  <div v-if="slice">
    <div v-if="slice.primary.heading && slice.primary.heading.length" class="bpg-title font-x-large font-weight-bold">
      {{ slice.primary.heading[0].text }}
    </div>
    <div v-if="slice.items.length" class="img-caption-box">
      <div v-for="(item, i) in slice.items" :key="'image_with_caption'+i" class="img-caption">
        <!-- <v-img v-if="item.image && item.image.url" :src="item.image.url" max-width="100%" height="auto" contain /> -->
        <div :max-width="item.image.dimensions.width">
          <img v-if="item.image && item.image.url" :src="item.image.url" width="100%">
        </div>
        <!-- <div v-if="item.image && item.image.url" :style="{backgroundImage: `url(${item.image.url})`, width: `${item.image.dimensions.width}px`, height: `${item.image.dimensions.height}px`, backgroundSize: 'cover'}" /> -->
        <div v-if="item.caption && item.caption.length" class="font-x-large font-weight-bold primary--text narrow-text">
          {{ item.caption[0].text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreeImageCaption',
  props: {
    slice: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.img-caption-box{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}
.img-caption {
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-content: center;
  align-items: center;
}
.narrow-text {
  width: 10em;
  text-align: center;
}
@media only screen and (max-width: 768px) {
.img-caption-box{
  width: 75%;
  padding-left: 12.5%;
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-content: center;
  align-items: center;
}
.img-caption {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}
.narrow-text {
  width: 20em;
  text-align: left;
}
}
</style>
