<template>
  <div class="py-6 d-flex justify-center">
    <div class="event-card d-flex flex-column flex-lg-row align-center">
      <v-img
        v-if="slice.event_image"
        :src="slice.event_image.url"
        :alt="slice.event_image.alt"
        width="320px"
        height="320px"
      />
      <v-img
        v-if="slice.book_cover_image && slice.book_cover_image.url"
        :src="slice.book_cover_image.url"
        :alt="slice.book_cover_image.alt"
        max-width="160px"
        max-height="253px"
        class="book-cover"
      />
      <div
        v-if="slice.event_date && $vuetify.breakpoint.mdAndDown"
        class="event-date font-medium font-weight-bold px-4 py-3"
      >
        {{ slice.event_date | formatDate('MMM DD, YYYY &nbsp;|&nbsp; h:mma') }}
      </div>
      <div class="content px-4 pa-lg-7">
        <div
          v-if="slice.heading && slice.heading.length && $vuetify.breakpoint.lgAndUp"
          class="event-heading mb-4"
        >
          {{ slice.heading[0].text }}
        </div>
        <div
          v-if="slice.mobile_heading && slice.mobile_heading.length && $vuetify.breakpoint.mdAndDown"
          class="event-heading mb-4"
        >
          {{ slice.mobile_heading[0].text }}
        </div>
        <div
          v-if="slice.text && slice.text.length && $vuetify.breakpoint.lgAndUp"
          class="font-medium font-weight-extra"
        >
          {{ slice.text[0].text }}
        </div>
        <div
          v-if="slice.mobile_text && slice.mobile_text.length && $vuetify.breakpoint.mdAndDown"
          class="sub-heading"
        >
          {{ slice.mobile_text[0].text }}
        </div>
        <base-button
          :text="slice.call_to_action_text"
          :href="slice.call_to_action.url"
          :target="slice.call_to_action.target"
          class="mt-6"
        />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'EventCard',
  props: {
    slice: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './scss/variables';

  .event-card {
    border: 1px solid $light-grey;
    background-color: white;

    .book-cover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-left: -80px;
    }

    .content {
      max-height: 320px;
      overflow-y: auto;

      .event-heading {
        font-weight: $font-weight-thin;
        font-size: 28px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    .event-card {
      max-width: 320px;

      .book-cover {
        display: none;
      }

      .event-date {
        margin-top: -24px;
        z-index: 1;
        position: relative;
        background-color: white;
      }

      .content {
        text-align: center;
        overflow-y: initial;

        .event-heading {
          font-size: 19px;
          font-weight: $font-weight-bold;
        }

        .sub-heading {
          font-size: 11px;
          font-weight: $font-weight-thin;
        }
      }

      .base-button {
        margin-bottom: -16px;
      }
    }
  }

</style>
