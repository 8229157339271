<template>
  <a :href="item.link && item.link.url" :target="item.link.target" class="recent-blog-list-item clickable">
    <div class="image">
      <img
        :src="item.featured_image.url"
        :alt="item.featured_image.alt"
      >
    </div>
    <div class="content">
      <div class="label">
        <span>{{ item.blog_post_type }}</span>
      </div>
      <div class="blog-title">
        <span>{{ item.post_title }}</span>
      </div>
      <div class="subtitle">
        <span>{{ item.post_date | formatDate('MMM DD, YYYY') }}</span>
      </div>
    </div>
  </a>
</template>

<script>

export default {
  name: 'BlogItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/variables';

  .recent-blog-list-item {
    text-decoration: none;
    flex-shrink: 0;
    width: 36em;
    margin-bottom: 4.4em;
    transition: box-shadow .3s;
    &:not(:last-child) {
      margin-right: 2.5em;
    }
    &:hover {
      box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.2);
    }
    .image img {
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $dark-grey;
      border: 1px solid #e4e4e4;
      border-top: none;
      height: 14.5em;
      line-height: 1.6em;
      .label {
        text-transform: uppercase;
        background-color: $off-white;
        margin-top: -2.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21.8em;
        height: 5.9em;
        span {
          font-size: 1.5em;
        }
      }
      .blog-title {
        margin-top: 2.3em;
        padding: 0 2.5em;
        text-align: center;
        overflow: hidden;
        max-height: 2.4em;
        span {
          font-size: 2.1em;
        }
      }
      .subtitle {
        margin-top: .8em;
        font-weight: $font-weight-default;
        span {
          font-size: 1.7em;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .blog-title {
      line-height: initial;
    }
  }
</style>
