<template>
  <section v-if="slice.items.length">
    <v-expansion-panels v-if="$breakpoint.is.smAndDown" v-model="expanded" class="px-3 py-2">
      <v-expansion-panel
        v-for="(item, i) of slice.items"
        :key="'content-section-slice-item_'+i"
        class="mobile-panel"
      >
        <v-expansion-panel-header :expand-icon="expanded === 0 ? 'mdi-minus' : 'mdi-plus'">
          <prismic-rich-text
            :field="item.content_heading"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <prismic-rich-text
            :field="item.content_body"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="$breakpoint.is.mdAndUp">
      <v-row
        v-for="(item, i) of slice.items"
        :key="'content-section-slice-item_'+i"
      >
        <v-col xs="12" md="12" lg="12" class="content-section-wrapper--light-green">
          <div class="bpg-title">
            <prismic-rich-text
              :field="item.content_heading"
            />
          </div>
          <div class="content font-weight-light">
            <prismic-rich-text
              :field="item.content_body"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentSection',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .bpg-title {
    font-size: 10px;
    margin: 0 0 2.6em 0;

    * {
      font-size: 2em;
      font-weight: $font-weight-default;
    }
  }

  .v-expansion-panel-content {
    padding-top: 24px;
  }

  .content-section-wrapper {
    font-size: 1.6em;
    width: 100%;
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 60px;
    .read-more {
      text-align: center;
      margin-top: 2em;
      a {
        font-weight: $font-weight-bold;
      }
    }
  }

  .content-section-wrapper--light-green {
    @extend .content-section-wrapper;
    background-color: $even-lighter-green;
  }
</style>
