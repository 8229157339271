<template>
  <section v-if="slice.items.length" class="group-container-single-col">
      <div
        v-if="slice.primary.faq_category && slice.primary.faq_category.length > 0"
        class="category-title mt-4"
>
      <h2>{{ slice.primary.faq_category[0].text }}</h2>
      </div>
      <div
      v-for="(item, i) of slice.items"
      :key="'faq-list-item' + i"
      :item="item"
      class="faq-group"
      >
        <div class="faq-item">
          <div class="faq-question font-large mt-1">
            <a @click="item.showAnswer = !item.showAnswer">
              <v-icon v-if="!item.showAnswer" class="primary--text font-large mr-2">
                    mdi-plus
                  </v-icon>
              <v-icon v-if="item.showAnswer" class="primary--text font-large mr-2">
                    mdi-minus
                  </v-icon>
              <span class="faq-question">{{ item.question }}</span></a>
          </div>
          <div v-if="item.showAnswer" class="font-large px-8 mt-2 highlighted">
            <prismic-rich-text
                  :field="item.answer"
                />
          </div>
          <div v-if="item.showAnswer && item.more_info_url.url" class="font-large px-8 faq-link highlighted">
            <span>More Info: <a :href="item.more_info_url.url" target="_blank">Click Here</a></span>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'FaqQuestion',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';
    .highlighted {
      background-color: #f1f4ed;
      padding:10px;
    }
    .faq-link {
      padding-bottom:20px;
    }
    .faq-question {
      a:hover {
        text-decoration:none !important;
      }
    }
</style>
