<template>
  <div class="scale-with-screen">
    <div class="book-cover-reel">
      <div v-if="reelTitle && reelTitle.length > 0" class="bpg-title" :class="titleClasses">
        <span>{{ reelTitle }}</span>
      </div>
      <base-loader v-if="loading || loadingInternal" />
      <div v-if="!loading">
        <div v-if="items.length > 5 && canPageLeft()" v-a11y-clickable class="left-arrow clickable" @click="paginateReel()" />
        <div v-if="items.length > 5 && canPageRight()" v-a11y-clickable class="right-arrow clickable" @click="paginateReel(true)" />
        <div class="book-cover-reel-scroller" :class="{ 'book-cover-reel-scroller-nested': nested }">
          <div
               class="book-cover-reel-list"
               :style="{ left: paginateLeft }"
>
            <book-cover-thumbnail
              v-for="(item, i) of items"
              :key="'book-cover-thumbnail_'+item.id + i"
              :item="item"
              :cover-only="coverOnly"
              :class="{
                'last-book-cover-thumbnail': (i === items.length -1)
              }"
              :cart-item="cartItem"
            />
          </div>
</div>
      </div>
    </div>
  </div>
</template>

<script>
import BookCoverThumbnail from '@/components/content-sections/book-cover-thumbnail'

export default {
  name: 'BookCoverReel',
  components: { BookCoverThumbnail },
  props: {
    reelTitle: {
      type: String,
      default: 'New Releases'
    },
    reelItems: {
      type: Array,
      default: () => ([])
    },
    randomList: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 5
    },
    titleClasses: {
      type: String,
      default: null
    },
    cartItem: {
      type: Boolean,
      default: false
    },
    nested: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    coverOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paginateIndex: 0,
      paginateLeft: '',
      items: [],
      loadingInternal: false
    }
  },
  /* watch: {
    'reelItems' () {
      this.setItems()
    }
  }, */
  created () {
    this.setItems()
  },
  methods: {
    dynamicPageSize () {
      return this.nested === true ? 3 : this.pageSize
    },
    canPageLeft () {
      return this.paginateIndex > 0
    },
    canPageRight () {
      return (this.paginateIndex + 1) * this.dynamicPageSize() < this.items.length
    },
    paginateReel (next = false) {
      if (!next && this.canPageLeft()) {
        this.paginateIndex -= 1
      } else if (this.canPageRight()) {
        this.paginateIndex += 1
      }
      if (this.nested === true) {
        this.paginateLeft = (this.paginateIndex * -66.13) + 'em'
      } else {
        this.paginateLeft = (this.paginateIndex * -87.6) + 'em'
      }
    },
    setItems () {
      if (this.reelItems && this.reelItems.length && this.reelItems[0].isbn && !this.items.length) {
        // This call will be skipped if the data is loaded in the parent component's asyncData method
        const isbns = this.reelItems.map(x => x.isbn)
        this.loadingInternal = true
        this.$axios.get('store/products/by-isbns', {
          params: {
            isbns
          }
        })
          .then((res) => {
            this.items = res.data
          })
          .finally(() => {
            this.loadingInternal = false
          })
      } else {
        this.items = this.reelItems
      }
      if (this.randomList && this.randomList === true) {
        this.items = this.items.sort((a, b) => Math.random() > 0.5 ? -1 : 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .book-cover-reel {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    &:before {
      content: '';
      background-color: $even-lighter-green;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 20em;
      z-index: 0;
    }
    .left-arrow, .right-arrow {
      border-color: $charcoal-grey;
      border-style: solid;
      border-width: 1px 1px 0 0;
      width: 3em;
      height: 3em;
      transform-origin: 50% 50%;
      position: absolute;
      top: 18em;
      z-index: 50;
    }
    .left-arrow {
      transform: rotate(-135deg);
      left: 2em;
    }
    .right-arrow {
      transform: rotate(45deg);
      right: 2em;
    }
  }

  .book-cover-reel-scroller {
    position: relative;
    max-width: 110em;
    width: 100%;
    padding-bottom: 1em;
    overflow: hidden;
  }

  .book-cover-reel-scroller-nested {
  position: relative;
    max-width: 66.5em;
    width: 100%;
    padding-bottom: 1em;
    overflow: hidden;
  }
  .book-cover-reel-list {
    display: flex;
    justify-content: flex-start;
    margin-top: 2.5em;
    position: relative;
    left: 0;
    transition: left .5s;
  }
 @media screen and (min-width: 2150px) {
   .book-cover-reel-scroller {
    position: relative;
    max-width: 96.5em;
    width: 100%;
    padding-bottom: 1em;
    overflow: hidden;
  }

  .book-cover-reel-scroller-nested {
  position: relative;
    max-width: 66.5em;
    width: 100%;
    padding-bottom: 1em;
    overflow: hidden;
  }
  }
  @media only screen and (max-width: 750px) {
    .book-cover-reel {
      font-size: 220%;
    }
    .book-cover-reel-scroller {
      width: 54em;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .book-cover-reel-list {
      left: 0 !important;
      font-size: 80%;
    }
    .book-cover-reel {
      .left-arrow, .right-arrow {
        display: none;
      }
      .content {
        width: 16.4em;
        font-size: 120%;
      }
      &::before {
        left: -4%;
        right: -4%;
      }
    }
  }
</style>
