<template>
  <div v-if="items && items.length" class="scale-with-screen">
    <div class="highlights">
      <highlight
        v-for="(item, i) of items"
        :key="'highlight'+i"
        :item="item"
      />
    </div>
  </div>
</template>
<script>
import Highlight from '@/components/slices/highlight'

export default {
  name: 'Highlights',
  components: { Highlight },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
  .highlights {
    display: flex;
    margin-top: 5em;
    justify-content: space-around;
    margin-bottom: 2em;
  }

  @media only screen and (max-width: 750px) {
    .highlights {
      flex-direction: column;
      align-items: center;
      font-size: 260%;
    }
  }
</style>
