<template>
  <div>
    <div v-if="slice.items.length">
      <prismic-rich-text
        v-for="(item, i) of slice.items"
        :key="'page-text-slice_'+i"
        :field="item.text_content"
        class="font-default-size page-text"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageText',
  props: {
    slice: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-text {
  padding: 2em;
}
.block-img {
  &.float-right {
    padding: 12px 0 12px 24px;
  }
  &.float-left {
    padding: 12px 24px 12px 0;
  }
}
</style>
