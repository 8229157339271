<template>
  <section v-if="slice.items.length" class="group-container-single-col">
      <v-row class="product-group-header">
        <v-col xs="10" md="10" lg="10">
          <div v-if="slice.primary && slice.primary.product_group" class="product-group-title">
            {{ slice.primary.product_group }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2">
          <div class="product-group-title" />
        </v-col>
      </v-row>
      <v-row
        v-for="(item, i) of slice.items"
        :key="'content-section-slice-item_'+i"
        class="product-item-list"
      >
        <v-col xs="10" md="10" lg="10" class="no-vertical-padding">
          <div class="product-title">
          {{ item.product_name }}
          </div>
        </v-col>
        <v-col cs="2" md="2" lg="2" class="no-vertical-padding">
          <div class="product-price">
            {{ item.price }}
          </div>
        </v-col>
      </v-row>
  </section>
</template>

<script>
export default {
  name: 'CafeItemSinglePrice',
  props: {
    slice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      expanded: null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';
</style>
