<template>
      <div
        v-if="slice && slice.hero_image && slice.hero_image.url"
        class="banner"
        :class="{
          'hero': hero,
          'ma-6 mx-md-0': !hero
        }"
        :style="{ backgroundImage: `url('${slice.hero_image.url}')`, width: `${slice.hero_image.dimensions.width}px`, height: `${slice.hero_image.dimensions.height}px`}"
      >
        <h1 v-if="slice.hero_text && slice.hero_text.length" class="banner-title">
          {{ slice.hero_text[0].text }}
        </h1>
      </div>
</template>

<script>
export default {
  name: 'HeroBanner',
  props: {
    slice: {
      type: Object,
      required: true
    },
    hero: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.banner {
    height: 100em;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5em;

    .banner-title {
        margin-bottom: 0.75em;
        margin-top: 0.9em;
        text-align: center;
        background-color: white;
        color: $black;
        font-size: 6em !important;
    }

    &.hero {
        height: 45em;

        .banner-title {
            font-size: 6em !important;
        }
    }
}

@media only screen and (max-width: 960px) {
    .banner {
        font-size: 1.04vw;
    }
}
</style>
